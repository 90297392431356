import React, { useState } from "react";
import Chatbot from "./chatbot";
import {
  chatbotData,
  jobDescriptionFileUpload,
} from "../../redux/action/chatbotAction";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import Header from "../../components/header";
import { useNavigate } from "react-router";
import Loader from "../../components/Loader";
import {questionsList} from './helper'

const ChatbotQuestions = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [questions, setQuestions] = useState(questionsList);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1);
  const [question, setQuestion] = useState(questions[1]);
  const [messages, setMessages] = useState([
    { id: 2, text: questions[1]["Q2"], sender: "bot" },
  ]);
  const [predefinedAnswers, setPredefinedAnswers] = useState(
    questions[0]["A1"]
  );
  const [sortedPdfAnswers, setSortedPdfAnswers] = useState({});
  const [updatedAnswers, setUpdatedAnswers] = useState([]);
  const [isPdfUploading, setPdfUploading] = useState(false);
  
  const handleOnSendMessage = async (file, msg) => {
    // Simulate an API call to get bot response
    console.log('msg12',msg,currentQuestionIndex);
    const tempQuestions = [...questions];
    console.log("tempQuestions", tempQuestions);
    tempQuestions[currentQuestionIndex][
      "A" + questions[currentQuestionIndex].id
    ] = msg;
    setQuestions(tempQuestions);
    console.log('messages12',messages);
    if (currentQuestionIndex === 3) {
      if (msg.includes("Yes")) {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: prevMessages.length + 1,
            text: questions[4]["Q5"],
            sender: "bot",
          },
        ]);
        setCurrentQuestionIndex(4);
        setQuestion(questions[4]);
        const tempAnswers = questions[4]["A" + questions[4].id];
        if (tempAnswers.length > 0) {
          setPredefinedAnswers(tempAnswers);
        } else {
          setPredefinedAnswers("");
        }
        return;
      } else {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: prevMessages.length + 1,
            text: questions[5]["Q6"],
            sender: "bot",
          },
        ]);
        setCurrentQuestionIndex(5);
        setQuestion(questions[5]);
        const tempAnswers = questions[5]["A" + questions[5].id];
        console.log("tempAnswers1234", tempAnswers);
        if (tempAnswers.length > 0) {
          setPredefinedAnswers(tempAnswers);
        } else {
          setPredefinedAnswers("");
        }
        return;
      }
    } 
    else if (currentQuestionIndex === 33) {
      if (msg === "Enhance My Text") {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: prevMessages.length + 1,
            text: questions[34]["Q35"],
            sender: "bot",
          },
        ]);
        setCurrentQuestionIndex(34);
        setQuestion(questions[34]);
        const tempAnswers = questions[34]["A" + questions[34].id];
        console.log("tempAnswers80", tempAnswers);
        if (tempAnswers.length > 0) {
          setPredefinedAnswers(tempAnswers);
        } else {
          setPredefinedAnswers("");
        }
        return;
      } else if (msg === "Proceed Now") {
        handleQuestionPayload();
        return;
      }
    } 
    
    else if (currentQuestionIndex === 26) {
      if (msg === "Monthly") {
        console.log("Monthly");
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: prevMessages.length + 1,
            text: questions[27]["Q28"],
            sender: "bot",
          },
        ]);
        setCurrentQuestionIndex(27);
        setQuestion(questions[27]);
        const tempAnswers = questions[27]["A" + questions[27].id];
        console.log("tempAnswers80", tempAnswers);
        if (tempAnswers.length > 0) {
          setPredefinedAnswers(tempAnswers);
        } else {
          setPredefinedAnswers("");
        }
        return;
      } 
   
      else if (msg === "Yearly" || msg!=='Monthly') {
        console.log("Yearly");
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: prevMessages?.length + 1,
            text: questions[28]["Q29"],
            sender: "bot",
          },
        ]);
        // else
        setCurrentQuestionIndex(28);
        setQuestion(questions[28]);
        const tempAnswers = questions[28]["A" + questions[28].id];
        console.log("tempAnswers1234", tempAnswers);
        if (tempAnswers?.length > 0) {
          setPredefinedAnswers(tempAnswers);
        } else {
          setPredefinedAnswers("");
        }
        return;
      }
    } else if (currentQuestionIndex === 27) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: prevMessages?.length + 1,
          text: questions[29]["Q30"],
          sender: "bot",
        },
      ]);
      setCurrentQuestionIndex(29);
      setQuestion(questions[29]);
      console.log("tempAnswers79");
      const tempAnswers = questions[29]["A" + questions[29].id];
      console.log("tempAnswers1234", tempAnswers);
      if (tempAnswers?.length > 0) {
        setPredefinedAnswers(tempAnswers);
      } else {
        setPredefinedAnswers("");
      }
      return;
    }
    else if (file) {
      await uploadFile(file);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: prevMessages?.length + 1,
          text: questions[currentQuestionIndex + 1][
            "Q" + questions[currentQuestionIndex + 1].id
          ],
          sender: "bot",
        },
      ]);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setQuestion(questions[currentQuestionIndex + 1]);
      const tempAnswers =
        questions[currentQuestionIndex + 1][
          "A" + questions[currentQuestionIndex + 1].id
        ];
      console.log("tempAnswers1234", tempAnswers);
      return;
    } else {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: prevMessages?.length + 1,
          text: questions[currentQuestionIndex + 1][
            "Q" + questions[currentQuestionIndex + 1].id
          ],
          sender: "bot",
        },
      ]);
      if (currentQuestionIndex === 27) {
        if (msg === "Monthly") {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
          setQuestion(questions[currentQuestionIndex + 1]);
          const tempAnswers =
            questions[currentQuestionIndex + 1][
              "A" + questions[currentQuestionIndex + 1].id
            ];
          if (tempAnswers?.length > 0) {
            setPredefinedAnswers(tempAnswers);
          } else {
            setPredefinedAnswers("");
          }
        } else {
          setCurrentQuestionIndex(currentQuestionIndex + 2);
          setQuestion(questions[currentQuestionIndex + 2]);
          const tempAnswers =
            questions[currentQuestionIndex + 2][
              "A" + questions[currentQuestionIndex + 2].id
            ];
          if (tempAnswers?.length > 0) {
            setPredefinedAnswers(tempAnswers);
          } else {
            setPredefinedAnswers("");
          }
        }
      } else {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setQuestion(questions[currentQuestionIndex + 1]);
        const tempAnswers =
          questions[currentQuestionIndex + 1][
            "A" + questions[currentQuestionIndex + 1].id
          ];
        if (tempAnswers?.length > 0) {
          setPredefinedAnswers(tempAnswers);
        } else {
          setPredefinedAnswers("");
        }
      }
  return;
    }
  };
  
  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    console.log("formData", formData);
    setPdfUploading(true);
    const response = await fetch(
      "https://iqhirepdf.dev.vinove.com/get_answers",
      {
        method: "POST",
        body: formData,
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      console.log("responseData", responseData);
      // const sortedEntries=Object?.entries(responseData).sort((a, b) => a[0].localeCompare(b[0]));
      const entries = Object.entries(responseData);
      const sortedEntries = entries.sort((a, b) => {
        const keyA = a[0].match(/\d+/);
        const keyB = b[0].match(/\d+/);
        return parseInt(keyA) - parseInt(keyB);
      });
      const sortedResponse = Object.fromEntries(sortedEntries);
      // Update the questions array with sorted response values starting from A6
      let updatedQuestions = questions.map((question, index) => {
        const responseKey = `A${index - 5 + 1}`;
        if (index >= 5 && sortedResponse[responseKey]) {
          const answerKey = `A${index + 1}`;
          return { ...question, [answerKey]: sortedResponse[responseKey] };
        }
        return question;
      });
      setUpdatedAnswers(updatedQuestions);
      console.log("updatedQuestions", updatedQuestions);

      console.log("sortedResponse", sortedResponse);
      setSortedPdfAnswers(sortedResponse);
      setPdfUploading(false);
      return responseData.message;
    } else {
      return "Error uploading file.";
    }
  };

  const handleQuestionPayload = () => {
    const payload = {
      Benefits_and_Perks: "",
      Company_Culture_and_Values: "",
      Responsibilities: "",
      Compensation: "",
      Compensation_Currency: "",
      Salary_Range_yearly: "",
      Application_deadline: "",
      unique_value: "",
    };
    console.log("clicked", questions);
    for (const que of questions) {
      console.log("que", que);
      payload[que.key] = que["A" + que.id];
    }
    console.log("payload12", payload);

    dispatch(chatbotData(payload, setLoading));
    navigate("/job/vacancy", { state: { loading } });
  };

  return (
    <>
      {isPdfUploading ? (
        <Loader  isPdfUploading={isPdfUploading}/>
      ) : (
        <>
          <Header></Header>
          <Box>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="mb-40 mt-40"
              >
                <Typography
                  variant="h2"
                  className="fs-38 fw-600 text-center text-orange"
                >
                  Generate Job Vacancy Text
                </Typography>
                <Typography
                  variant="h2"
                  className="fs-16 fw-400 text-center m-auto"
                  sx={{ maxWidth: "720px" }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magn{" "}
                  {loading ? <CircularProgress /> : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Chatbot
                  updatedAnswers={updatedAnswers}
                  sortedPdfAnswers={sortedPdfAnswers}
                  handleContinue={handleQuestionPayload}
                  lastQuestion={questions.length - 1 === currentQuestionIndex}
                  preDefinedAnswer={predefinedAnswers}
                  inputType={question.inputType ? question.inputType : "text"}
                  messages={messages}
                  setMessages={setMessages}
                  currentQuestion={question}
                  questions={questions}
                  onSendMessage={handleOnSendMessage}
                  botLoadingMessage="Bot is typing..."
                  currentQuestionIndex={currentQuestionIndex}
                  setCurrentQuestionIndex={setCurrentQuestionIndex}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default ChatbotQuestions;

const ActionName = {
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  FORGOT: "FORGOT",
  VERIFYOTP: "VERIFYOTP",
  RESENDOTP: "RESENDOTP",
  RESET_PASSWORD: "RESET_PASSWORD",
  PROFILE:"PROFILE",
  COMPANYINFO:'COMPANYINFO',
  PROFILEINFO:"PROFILEINFO",
  CLIENTINFO:"CLIENTINFO",
  JOBDESCRIPTIONFILEUPLOAD:"JOBDESCRIPTIONFILEUPLOAD",
  CHATBOTDATA:"CHATBOTDATA",
  CHATBOTDATA_LOADING:"CHATBOTDATA_LOADING",
  TONE_OF_VOICE:"TONE_OF_VOICE",
  STORE_CHATBOT_PAYLOAD:"STORE_CHATBOT_PAYLOAD",
  EDITED_VACANCY_TEXT:"EDITED_VACANCY_TEXT",
  SOCIAL_MEDIA_TEMPLATE:"SOCIAL_MEDIA_TEMPLATE",
  SHARE_VACANCY_TEXT:"SHARE_VACANCY_TEXT",
  BEAUTIFY_TEMPLATE_DATA:"BEAUTIFY_TEMPLATE_DATA",
  VACANCYID:"VACANCYID"  
};

export default ActionName;


//List Of Chatbot Questions
export const questionsList = [
    {
      id: 1,
      Q1: "Welcome back! Let's start creating a new job vacancy",
      A1: "",
      inputType: "text",
      key: "Greet",
    },
    {
      id: 2,
      // Q2: `Could you please provide your company website URL? This will help us extract the tone
      //      of voice from your company's online presence and will also help us to get information regarding about us section. Additionally, after providing the URL, you'll
      //      be given the option to make the job vacancy anonymous`,
      Q2:`Could you please provide your company's About Us URL? This will help us extract the tone
           of voice from your company's online presence and will also help us to get information regarding about us section. Additionally, after providing the URL, you'll
           be given the option to make the job vacancy anonymous`,
      A2: "",
      inputType: "text",
      key: "website_url",
    },
    {
      id: 3,
      Q3: "would you like this job vacancy to be posted anonymously?",
      A3: "",
      inputType: "select",
      options: [
        "Yes, I prefer to keep the job vacancy anonymous",
        "No, it's okay to disclose the company's identity.",
      ],
      key: "job_vacancy",
    },
    {
      id: 4,
      Q4: "Do you have a job description available?",
      A4: "",
      inputType: "select",
      options: [
        "Yes, Please upload or paste the job description",
        "No, Do not worry, we are here to help you craft one",
      ],
      key: "job_description",
    },
    {
      id: 5,
      Q5: "Please upload the job description file.",
      A5: "",
      inputType: "file",
      key: "upload_file",
    },
    {
      id: 6,
      Q6: "What will be the job title?",
      A6: "",
      inputType: "text",
      key: "Job_Title",
      isUploadedQuestion:"Yes"
      
    },
    {
      id: 7,
      Q7: "Could you provide a brief overview of the role's purpose? Example: Answer to this question will help us to generate detailed description of the role overview which is required in one of the sub-sections within the vacancy text",
      A7: "",
      inputType: "text",
      key: "Role_Overview",
      isUploadedQuestion:"Yes"
    },
    {
      id: 8,
      Q8: "What is the job level required for this vacancy?",
      A8: "",
      inputType: "select",
      options: [
        "Internship",
        "Starting professional",
        "Mid-career professional",
        "Senior professional",
        "C-level (CEO, CFO, etc.)",
        "Custom...",
      ],
      key: "Job_level",
      isUploadedQuestion:"Yes"
    },
    {
      id: 9,
      Q9: "What tasks and duties will the employee need to perform regularly?",
      A9: "",
      inputType: "text",
      // options: [
      //   "Managing projects",
      //   "Client interaction",
      //   "Data analysis",
      //   "Team collaboration",
      //   "Custom...",
      // ],
      key: "Tasks_and_Duties",
      isUploadedQuestion:"Yes"
    },
    {
      id: 10,
      Q10: `What specific experience are you looking for in a candidate for this role (e.g., managerial experience,
recruitment background, etc.)?`,
      A10: "",
      inputType: "text",
      // options: [
      //   "Project management",
      //   "Sales experience",
      //   "Technical expertise",
      //   "Customer service",
      //   "Custom...",
      // ],
      key: "Relevant_Experience",
      isUploadedQuestion:"Yes"
    },
    {
      id: 11,
      Q11: "What is the total years of experience required for this role?",
      A11: "",
      inputType: "select",
      options: ["No experience necessary","0 to 5 years", "5 to 10 years", "More than 10 years",
        "Custom..."],
      key: "Years_of_Experience_Required",
      isUploadedQuestion:"Yes"
    },
    {
      id: 12,
      Q12: "What is the minimum education background required, and which field?",
      A12: "",
      inputType: "select",
      options: [
        "High school diploma",
        "Professional education",
        "Associate degree",
        "Bachelor's degree",
        "Master's degree",
        "PhD",
        "Custom..."
      ],
      key: "Min_education_background",
      isUploadedQuestion:"Yes"
    },
    {
      id: 13,
      Q13: "What are the fields of study?",
      A13: "",
      inputType: "text",
      // options: ["Custom..."],
      key: "Options_for_field_of_study",
      isUploadedQuestion:"Yes"
    },
    {
      id: 14,
      Q14: "What specific skills are required for the job?",
      A14: "",
      inputType: "text",
      // options: ["Custom..."],
      key: "Skills",
      isUploadedQuestion:"Yes"
    },
    {
      id: 15,
      Q15: "What specific certifications are required for the job?",
      A15: "",
      inputType: "text",
      // options: ["Custom..."],
      key: "Skills_and_Certifications",
      isUploadedQuestion:"Yes"
    },
    {
      id: 16,
      Q16: "What are the top 3 competencies for this position?",
      A16: "",
      inputType: "multi-select",
      maxSelects: 3,
      options: [
        "Leadership",
        "Communication",
        "Problem-solving",
        "Teamwork",
        "Innovation",
        "Custom...",
      ],
      key: "Top_Competencies",
      isUploadedQuestion:"Yes"
    },
    {
      id: 17,
      Q17: `What does first competency look like within your company?`,
      A17: "",
      inputType: "text",
      key: "Competency_Details",
      isUploadedQuestion:"Yes"
    },
    {
      id: 18,
      Q18: "What does Second Compentency look like within your company?",
      A18: "",
      inputType: "text",
      key: "Competency_Details",
      isUploadedQuestion:"Yes"
    },
    {
      id: 19,
      Q19: "What does Third Compentency look like within your company?",
      A19: "",
      inputType: "text",
      key: "Competency_Details",
      isUploadedQuestion:"Yes"
    },
    // {
    //   id: 20,
    //   Q20: "Who will the employee report to, and will they work independently or as part of a team?",
    //   A20: "",
    //   inputType: "select",
    //   options: [
    //     "Report to Manager",
    //     "Report to Team Lead",
    //     "Work independently",
    //     "Work as part of a team",
    //     "Custom...",
    //   ],
    //   key: "Reporting_Structure",
    // },
    {
      id: 20,
      Q20: "What are the main goals for this position?",
      A20: "",
      inputType: "text",
      // options: ["Custom..."],
      key: "Goals_Evaluations",
      isUploadedQuestion:"Yes"
    },
    {
      id: 21,
      Q21: "Can you describe the company culture and any specific values important for this role?",
      A21: "",
      inputType: "text",
      key: "company_highlights",
      isUploadedQuestion:"Yes"
    },
    {
      id: 22,
      Q22: "Are there opportunities for career advancement or professional development?",
      A22: "",
      inputType: "select",
      options: [
        "Promotion opportunities",
        "Training programs",
        "Mentorship programs",
        "Continuing education opportunities",
        "Job rotation programs",
        "Leadership development programs",
        "Certification reimbursement",
        "Networking opportunities",
        "Cross-functional team projects",
        "Custom...",
      ],
      key: "Key_Selling_Point",
      isUploadedQuestion:"Yes"
    },
    {
      id: 23,
      Q23: "Will this job be office-based, remote, or a mix of both??",
      A23: "",
      inputType: "select",
      options: ["Office-based", "Remote", "Hybrid",
        "Custom..."],
      key: "Work_environment",
      isUploadedQuestion:"Yes"
    },
    {
      id: 24,
      Q24: "Where is the job offering based?",
      A24: "",
      inputType: "text",
      key: "Job_Location",
      isUploadedQuestion:"Yes"
    },
    {
      id: 25,
      Q25: "Are you looking for permanent, temporary, or intern/freelance employment?",
      A25: "",
      inputType: "select",
      options: ["Permanent", "Temporary", "Intern", "Freelance",
        "Custom..."],
      key: "Employment_Type",
      isUploadedQuestion:"Yes"
    },
    {
      id: 26,
      Q26: "Please select the currency.",
      A26: "",
      inputType: "select",
      options: ["EUR", "USD", "GBP", "Custom..."],
      key: "currency",
      isUploadedQuestion:"Yes"
    },
    {
      id: 27,
      Q27: "Please select the salary type.",
      A27: "",
      inputType: "select",
      options: ["Monthly", "Yearly",
        "Custom..."],
      key: "salary_type",
      isUploadedQuestion:"Yes" 
    },
  
    {
      id: 28,
      Q28: `please select the salary range.`,
      A28: "",
      inputType: "select",
      // options: salaryTypeSelectedAnswer && salaryTypeSelectedAnswer==="Monthly"?["0-2,500", "2,500-3,750","3,750-5,000","5,000-7,000","7,000-10,000","10,000+","Custom... (please specify)"]:["0-30,000","30,000-45,000","45,000-60,000","60,000-84,000","84,000-120,000","120,000+","Custom... (please specify)"],
      options: [
        "0-2,500",
        "2,500-3,750",
        "3,750-5,000",
        "5,000-7,000",
        "7,000-10,000",
        "10,000+",
        "Don't Want To Mention",
        "Custom...",
      ],
      key: "salary_range",
      isUploadedQuestion:"Yes"
    },
    {
      id: 29,
      Q29: `please select the salary range.`,
      A29: "",
      inputType: "select",
      // options: salaryTypeSelectedAnswer && salaryTypeSelectedAnswer==="Monthly"?["0-2,500", "2,500-3,750","3,750-5,000","5,000-7,000","7,000-10,000","10,000+","Custom... (please specify)"]:["0-30,000","30,000-45,000","45,000-60,000","60,000-84,000","84,000-120,000","120,000+","Custom... (please specify)"],
      options: [
        "0-30,000",
        "30,000-45,000",
        "45,000-60,000",
        "60,000-84,000",
        "84,000-120,000",
        "120,000+",
        "Don't Want To Mention",
        "Custom...",
      ],
      key: "salary_range",
      isUploadedQuestion:"Yes"
    },
    {
      id: 30,
      Q30: "Please specify any additional benefits offered.",
      A30: "",
      inputType: "text",
      key: "additional_benefits",
      isUploadedQuestion:"Yes"
    },
    {
      id: 31,
      Q31: "What are the expected hours of work for this position?",
      A31: "",
      inputType: "text",
      key: "working_hours",
      isUploadedQuestion:"Yes"
    },
    {
      id: 32,
      Q32: "When is the application deadline?",
      A32: "",
      inputType: "text",
      key: "app_deadline",
      isUploadedQuestion:"Yes"
    },
    {
      id: 33,
      Q33: "How should candidates apply?",
      A33: "",
      inputType: "text",
      key: "application_platform",
      isUploadedQuestion:"Yes"
    },
    {
      id: 34,
      Q34: `Would you like to proceed with generating your job vacancy text now, or would you like to answer a
            few additional questions to further refine your text using value-driven elements, storytelling, and
            neuromarketing techniques?`,
      A34: "",
      options: ["Proceed Now", "Enhance My Text"],
      inputType: "select",
      key: "special_questions",
    },
    {
      id: 35,
      Q35: "What makes this job opportunity unique or valuable to potential candidates?",
      A35: "",
      inputType: "text",
      // options: [
      //   "Innovative work environment",
      //   "Career growth opportunities",
      //   "Competitive salary",
      //   "Great team culture",
      //   "Custom...",
      // ],
      key: "value_proposition",
      isUploadedQuestion:"Yes"
    },
    {
      id: 36,
      Q36: "What feelings do you want candidates to associate with this job opportunity?",
      A36: "",
      inputType: "select",
      options: ["Excitement", "Fulfillment", "Challenge", "Growth", "Stability",
        "Custom..."],
      key: "emotional_connection",
      isUploadedQuestion:"Yes"
    },
    {
      id: 37,
      Q37: "What specific benefits or perks would appeal most to potential candidates?",
      A37: "",
      inputType: "select",
      options: [
        "Flexible working hours",
        "Health benefits",
        "Paid time off",
        "Professional development",
        "Custom..."
      ],
      key: "key_selling_points",
      isUploadedQuestion:"Yes"
    },
    {
      id: 38,
      Q38: "How can we make the job vacancy text more engaging and memorable?",
      A38: "",
      inputType: "select",
      options: [
        "Pose a question for candidates to ponder",
        "Share a brief success story from a current employee",
        "Highlight an interesting project or initiative the successful candidate will be involved in",
        "Custom..."
      ],
      key: "engagement_strategy",
      isUploadedQuestion:"Yes"
    },
    {
      id: 39,
      Q39: "What tone or voice would best reflect the company culture and appeal to potential candidates?",
      A39: "",
      inputType: "select",
      options: [
        "Achievement",
        "Collaboration",
        "Opportunity",
        "Impact",
        "Growth",
        "Extracted tone of voice from company website",
        "Custom..."
      ],
      key: "Tone_of_Voice",
      isUploadedQuestion:"Yes"
    },

    {
      id: 40,
      Q40: "Which words or phrases do you think will resonate most with potential candidates?",
      A40: "",
      inputType: "select",
      options: [
        "Achievement",
        "Collaboration",
        "Opportunity",
        "Impact",
        "Growth",
        "Custom..."
      ],
      key: "language_and_phrasing",
      isUploadedQuestion:"Yes"
    },
    // {
    //   id: 40,
    //   Q40: `Does your company have a slogan? If so, please enter it below. If not, feel
    //         free to skip this question.`,
    //   A40: "",
    //   inputType: "text",
    //   // options: [
    //   //   "Achievement",
    //   //   "Collaboration",
    //   //   "Opportunity",
    //   //   "Impact",
    //   //   "Growth",  
    //   //   "Custom..."
    //   // ],
    //   key: "slogan",
    //   isUploadedQuestion:"Yes"
    // },
    {
      id: 41,
      Q41: "What makes our company great?",
      A41: "",
      inputType: "select",
      options: [
        "Our commitment to innovation and creativity.",
        "Our inclusive and supportive company culture.",
        "Our dedication to continuous learning and growth.",
        "Our focus on making a positive impact on society and the environment.",
        "Custom...",
      ],
      key: "what_makes_the_company_great",
      isUploadedQuestion:"Yes"
    },
    {
      id: 42,
      Q42: "What current trends or challenges do you see impacting this job opportunity? This could include industry shifts, societal changes, or global events. Example of Answer: With the rise of remote work and increasing focus on sustainability, this job opportunity offers a blendof flexibility and purpose-driven work aligned with evolving industry and societal needs. How can we tailor the job vacancy text to reflect these trends and emphasize the unique value proposition?",
      A42: "",
      inputType: "text",
      key: "Context",
      isUploadedQuestion:"Yes"
    },
    {
      id: 43,
      Q43: "We have reached to the end of the questions",
      A43: "",
      inputType: "text",
      key: "end",
    },
  ];

//Hashmap containing predefined competency values
export const competencyList = new Map([
    ['Leadership', "Provides others with clear instructions and guidelines. Adapts leadership style to the situation or individual."],
    ['Communication', "Keeps people actively informed and communicates relevant information in a clear and concise manner. Listens well and considers the opinions and points of view of others."],
    ['Problem-solving', "Dissects and analyses information effectively. Recognises the causes of problems and proposes realistic and practical solutions to address them."],
    ['Teamwork', "Maps the potential risks of a particular approach. Shows willingness to take responsible risks to achieve goals."],
    ['Innovation', "Comes up with new and original ideas, approaches, and solutions to problems. Looks beyond conventional approaches and established methods."]
]);
  
//function to check whether predefined answer matches the options in the question
export const IsOptionMatches=(currentQuestion,sortedPdfAnswers)=>{
    let currentQuestionsAnswer;
    if(currentQuestion?.key==="salary_range")
    currentQuestionsAnswer=sortedPdfAnswers[`A${currentQuestion?.id-6}`];
     else
    currentQuestionsAnswer=sortedPdfAnswers[`A${currentQuestion?.id-5}`];
    // const currentQuestionsAnswer=sortedPdfAnswers[`A${currentQuestion?.id-6}`];
    console.log("currentQuestionsAnswer25",currentQuestionsAnswer)
    const result=currentQuestion?.options?.filter((option)=>currentQuestionsAnswer?.includes(option));
    console.log('result12',result);
    return {IsOptionMatches:result.length>0,matchingOptions:result};
  }


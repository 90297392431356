import React, { useState, useEffect, useRef } from "react";
import "./Chatbot.scss";
import { competencyList, IsOptionMatches } from "./helper";
import { Box, Button, IconButton } from "@mui/material";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import Tooltip from "@mui/material/Tooltip";
import gallary from "../../assets/img/svg/gallary.svg";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { useSelector } from "react-redux";

const Chatbot = ({
  sortedPdfAnswers,
  updatedAnswers,
  handleContinue,
  lastQuestion,
  questions,
  onSendMessage,
  botLoadingMessage,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  currentQuestion,
  messages,
  setMessages,
  inputType,
}) => {
  const [message, setMessage] = useState("");
  const sendBtn = useRef(null);
  const chatEndRef = useRef(null);
  const [file, setFile] = useState(null);
  const [botTyping, setBotTyping] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [multiMessage, setMultiMessage] = useState("");
  const [isOptionPresent, setIsOptionPresent] = useState(false);
  const [competencies, setCompetencies] = useState([]);
  const fileUploadRef = useRef(null);
  const userData = useSelector(
    (state) => state?.authenticationReducer?.data?.user
  );

  useEffect(() => {
    if (
      Object.keys(sortedPdfAnswers).length > 0 &&
      currentQuestionIndex > 4 &&
      competencies.length === 0 &&
      currentQuestion?.isUploadedQuestion
    ) {
      if (
        currentQuestion?.inputType === "text" &&
        sortedPdfAnswers[`A${currentQuestion?.id - 5}`] !== undefined
      ) {
        console.log(
          "currentQuestion65",
          sortedPdfAnswers[`A${currentQuestion?.id - 5}`]
        );
        setMessage(sortedPdfAnswers[`A${currentQuestion?.id - 5}`]);
        console.log("test", inputType);
      } else if (currentQuestion?.inputType === "select") {
        const matchingoptions = IsOptionMatches(
          currentQuestion,
          sortedPdfAnswers
        );
        if (matchingoptions.IsOptionMatches) {
          setIsOptionPresent(true);
        } else {
          setIsOptionPresent(false);
        }
        if (!isOptionPresent) {
          console.log("custom");
          setMessage("Custom...");
          if (currentQuestion?.key === "salary_range")
            setMultiMessage(sortedPdfAnswers[`A${currentQuestion?.id - 6}`]);
          else setMultiMessage(sortedPdfAnswers[`A${currentQuestion?.id - 5}`]);
        }
      } else {
        currentQuestion?.options?.forEach((data) => {
          console.log(
            "data35",
            sortedPdfAnswers[`A${currentQuestion?.id - 5}`]
          );
          if (data === sortedPdfAnswers[`A${currentQuestion?.id - 5}`]) {
            setMessage(data);
            setIsOptionPresent(true);
          }
        });
        if (!isOptionPresent) {
          setMessage("Custom...");
          setMultiMessage(sortedPdfAnswers[`A${currentQuestion?.id - 5}`]);
        }
      }
    } else {
      if (currentQuestionIndex === 16) {
        setMessage(competencyList.get(competencies[0]));
      }
      if (currentQuestionIndex === 17) {
        setMessage(competencyList.get(competencies[1]));
      }
      if (currentQuestionIndex === 18) {
        setMessage(competencyList.get(competencies[2]));
      }
    }
  }, [sortedPdfAnswers, currentQuestion, currentQuestionIndex]);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleMessageExport = async (message, file) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: prevMessages.length + 1, text: message, sender: "user" },
    ]);
    await onSendMessage(file, message);
    scrollToBottom();
  };

  const handleSendMessage = async () => {
    setProcessing(true);
    if (message?.trim() || file) {
      setBotTyping(true);
      await handleMessageExport(message, file);
      if (!sortedPdfAnswers["A1"]) {
        if (!(currentQuestionIndex > 15 && currentQuestionIndex < 18)) {
          setMessage("");
        }
        setMultiMessage("");
        setFile(null);
      } else {
        if (currentQuestionIndex === 17) {
          setCompetencies("");
        }
      }
    }
    setProcessing(false);
  };
  const handleOk = async () => {
    setProcessing(true);
    setBotTyping(true);
    await handleMessageExport("Ok", file);

    setProcessing(false);
  };
  const handleTextMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleSelectChange = async (option) => {
    if (option.includes("Custom...")) {
      inputType = "multi-text";
      setMessage(["Custom..."]);
      return;
    }
    if (processing) return;
    setProcessing(true);
    if (option?.trim() || file) {
      setBotTyping(true);
      await handleMessageExport(option, file);
      if (!sortedPdfAnswers["A1"]) {
        setMessage("");
        setMultiMessage("");
        setFile(null);
      }
    }
    setProcessing(false);
  };
  const handleMultiSelectChange = (option) => {
    if (message?.includes(option)) {
      console.log("option", message);
      const tempArr = message?.split(", ");
      const index = tempArr?.indexOf(option);
      if (index > -1) {
        tempArr?.splice(index, 1);
      }
      setMessage(tempArr.join(", "));
      return;
    }
    if (message) {
      const mesarray = [];
      mesarray.push(option);
      const tempMessage = message + ", " + option;
      const options = tempMessage.split(", ");
      const isCustomSelected = options.filter((ele) => ele === "Custom...");
      if (options.length <= 3 && isCustomSelected.length !== 1) {
        setMessage(tempMessage);
        setCompetencies(options);
      }
    } else {
      setMessage(option);
    }
  };

  const handleFileChange = async (e) => {
    console.log(processing);
    if (processing) return;
    const selectedFile = e.target.files[0];
    setFile(e.target.files[0]);
    setMessage(e.target.files[0].name);
    setProcessing(true);
    if (message?.trim() || selectedFile) {
      setBotTyping(true);
      await handleMessageExport(e.target.files[0].name, selectedFile);
      setMessage("");
      setFile(null);
    }
    setProcessing(false);
  };

  const handleMultiTextMessage = (e) => {
    setMultiMessage(e.target.value);
  };

  const handleCustomSelect = async (e) => {
    if (inputType === "select") {
      setProcessing(true);
      if (multiMessage?.trim() || file) {
        setBotTyping(true);
        await handleMessageExport(multiMessage, file);
        if (!sortedPdfAnswers["A1"]) {
          setFile(null);
          setMultiMessage("");
          setMessage("");
        }
      }
      setProcessing(false);
    } else if (inputType === "multi-select") {
      setProcessing(true);
      const tempMessages = message?.split(", ");
      const index = tempMessages?.findIndex((item) => item === "Custom...");
      if (tempMessages) {
        tempMessages[index] = multiMessage;
        const options = tempMessages.join(", ");
        setBotTyping(true);
        await handleMessageExport(options, file);
      }
      setFile(null);
      setMultiMessage("");
      setProcessing(false);
    }
  };
  return (
    <Box className="chatbot">
      <Box className="messages">
        <p style={{ fontSize: "20px" }}>
          {`Welcome back, ${userData?.fullName}! Let's start creating a new job vacancy`}
        </p>
        {messages?.map((msg) => (
          <Box
            key={msg.id}
            className={`message ${msg.sender}-message`}
            ref={chatEndRef}
          >
            <Box className="message-content">
              <Box className="message-header">
                <img
                  src={`https://cdn-icons-png.flaticon.com/512/21/21104.png`}
                  alt={msg.sender}
                  className="message-avatar"
                />
                <span className="message-username">
                  {msg.sender === "user" ? `${userData?.fullName}` : "Virtual Assistant"}
                </span>
              </Box>
              <p className="message-text" ref={chatEndRef}>
                {msg?.text}
              </p>
              {currentQuestion.inputType === "select" &&
                msg.sender === "bot" &&
                msg.text ===
                  currentQuestion["Q" + (currentQuestionIndex + 1)] && (
                  <Box>
                    {currentQuestion.options.map((option, index) => {
                      console.log(`Option: ${option}, Message: ${message}`);
                      return (
                        <Box
                          key={`option-${index}`}
                          onClick={() => handleSelectChange(option)}
                          className={`select-option ${
                            message?.includes(option) ? "selected" : ""
                          }`}
                        >
                          {option}
                        </Box>
                      );
                    })}
                  </Box>
                )}
              {currentQuestion.inputType === "multi-select" &&
                msg.sender === "bot" &&
                msg.text ===
                  currentQuestion["Q" + (currentQuestionIndex + 1)] && (
                  <Box>
                    {currentQuestion.options.map((option, index) => (
                      <Box
                        key={`option-${index}`}
                        onClick={() => handleMultiSelectChange(option)}
                        className={`select-option ${
                          message?.includes(option) ? "selected" : ""
                        }`}
                      >
                        {option}
                      </Box>
                    ))}
                  </Box>
                )}
            </Box>
          </Box>
        ))}

        {currentQuestionIndex === 0 && (
          <div className="message user-message">
            <div className="message-content">
              <Button
                className="continue-button primary-btn primary-btn-outline"
                onClick={() => {
                  handleOk();
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        )}
        {lastQuestion && (
          <div className="message user-message">
            <div className="message-content">
              <button
                className="continue-button primary-btn primary-btn-outline"
                onClick={() => {
                  handleContinue();
                }}
              >
                Continue
              </button>
            </div>
          </div>
        )}
      </Box>
      <Box className="input-container">
        <Box className="file-input-container">
          <input
            ref={fileUploadRef}
            type="file"
            title=""
            style={{ zIndex: 1 }}
            onChange={(e) => {
              handleFileChange(e);
            }}
            className="file-input"
            disabled={inputType === "file" ? false : true}
          />
          {currentQuestionIndex === 4 ? (
            <Tooltip title="Please click on it to upload a file" arrow>
              <IconButton
                sx={{ margin: 0, padding: 0, zIndex: 2 }}
                onClick={() => fileUploadRef.current?.click()}
              >
                <PhotoLibraryIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <PhotoLibraryIcon />
          )}
        </Box>
        {(inputType === "select" || inputType === "multi-select") &&
        message?.includes("Custom...") ? (
          <textarea
            type="text"
            value={multiMessage}
            onChange={handleMultiTextMessage}
            placeholder="Type your message..."
            className="message-input"
          />
        ) : (
          <textarea
            type="text"
            value={inputType === "text" ? message : ""}
            onChange={(e) => {
              handleTextMessage(e);
            }}
            placeholder="Type your message..."
            className="message-input"
            disabled={
              inputType === "text" &&
              !processing &&
              currentQuestionIndex !== 0 &&
              !lastQuestion
                ? false
                : true
            }
          />
        )}
        <Button
          ref={sendBtn}
          onClick={() => {
            inputType === "select" || inputType === "multi-select"
              ? handleCustomSelect()
              : handleSendMessage();
          }}
          disabled={processing || lastQuestion || currentQuestion?.id===34}
          className="send-button p-0 text-orange bg-none"
          sx={{ minWidth: "Fit-content" }}
        >
          <SendOutlinedIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default Chatbot;
